import { createStore } from 'vuex';
import * as dbApi from '@/database';
import * as connApi from '@/conn';

const store = createStore({
  state: {
    userId: null,
    username: null,
    password: null,
    displayedMatches: [],
    invitableUsers: [],
    creatableGames: [],
    activeMatchId: null,
    activeGameData: null
  },
  mutations: {
    SET_ACTIVE_GAMEDATA(state, gameData) {
      if (gameData !== null) gameData["keyId"] = (state.activeGameData === null) ? 0: state.activeGameData.keyId + 1;
      state.activeGameData = gameData;
    },
    SET_ACTIVE_MATCHID(state, matchId) {
      state.activeMatchId = matchId;
    },
    REMOVE_DISPLAYED_MATCH(state, matchId) {
      const index = state.displayedMatches.findIndex(dm => dm.matchId === matchId);
      if (index !== -1) {
        state.displayedMatches.splice(index, 1);
      } else {
        console.error("Error removing displayed match");
      }
    },
    SET_DISPLAYED_MATCHES(state, matches) {
      matches.forEach(match => {
          const index = state.displayedMatches.findIndex(dm => dm.matchId === match.matchId);
          if (index !== -1) {
            match["keyId"] = state.displayedMatches[index].keyId + 1;
            state.displayedMatches[index] = match;
          } else {
            match["keyId"] = 0;
            state.displayedMatches.push(match);
          }
      });
    },
    SET_USERNAME(state, name) {
      state.username = name;
    },
    SET_USERID(state, userId) {
      state.userId = userId;
    },
    SET_PASSWORD(state, pwd) {
      state.password = pwd;
    },
    SET_USERS(state, users) {
      state.invitableUsers = users;
    },
    SET_GAMES(state, games) {
      state.creatableGames = games;
    }
  },
  actions: {
    async login({ commit }, { username, password }) {
      try {
        const response = await fetch('/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password }),
        });
  
        if (response.ok && response.headers.get('content-type')?.includes('application/json')) {
          const result = await response.json();
          dbApi.setToken(result.token);
          dbApi.setUserId(result.user.id);
          dbApi.setName(result.user.username);
          dbApi.setPwd(result.user.password);
          commit('SET_USERNAME', result.user.username);
          commit('SET_USERID', parseInt(result.user.id));
          commit('SET_PASSWORD', result.user.password);
        } else {
          throw new Error('Login failed');
        }
      } catch (error) {
        console.error('Login error:', error);
        connApi.toastError(error);
      }
    },     
    async register({ commit }, { username, password }) {
        try {
          const response = await fetch('/api/register', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
          });
      
          const result = await response.json();
          if (response.ok) {
            dbApi.setName(result.user.username);
            dbApi.setPwd(result.user.password);
            commit('SET_USERNAME', result.user.username);
            commit('SET_PASSWORD', result.user.password);
            connApi.toastSuccess('User created successfully. Please login.');
          } else {
            throw new Error(result.message || 'There was an error registering the account.');
          }
        } catch (error) {
          console.error('Registration error:', error);
          connApi.toastError(error);
        }
    },   
    async clearInfo({ commit }) {
      dbApi.removeUserId();
      dbApi.removeName();
      dbApi.removeToken();
      dbApi.removePwd();
      await dbApi.clearDatabase();
      connApi.disconnectFromServer();
      commit('SET_USERID', null);
      commit('SET_USERNAME', null);
      commit('SET_PASSWORD', null);
    },
    onMatchesPage({ commit }) {
      if (!connApi.connected) {
        connApi.connectSocket().then(() => {
          connApi.onMatchesPage();
          connApi.getCreateMatchData();
          commit('SET_USERID', dbApi.getUserId());
          commit('SET_USERNAME', dbApi.getName());
          commit('SET_PASSWORD', dbApi.getPwd());
        }).catch((error) => {
          console.error('Error on matches page:', error);
        });
      } else {
        connApi.onMatchesPage();
      }
    },
    connectToServer() {
      return connApi.connectSocket();
    },
    disconnectFromServer() {
      connApi.disconnectFromServer();
    },
    async loadDisplayedMatches({ commit }) {
      const matches = await dbApi.getDisplayedMatches();
      commit('SET_DISPLAYED_MATCHES', matches);
    },
    sendInvite(_, { matchId, accepted }) {
      connApi.acceptInvite(matchId, accepted);
    },
    onCreateMatchPage() {
      connApi.getCreateMatchData();
    },
    offCreateMatchPage() {
      connApi.stopListeningToEvent('fetchedCreateMatchData');
    },
    createMatch(_, { gid, settings }) {
      const payload = {
        gameId: gid,
        selected_settings: settings
      };
      connApi.emitCreateMatch(payload);
    },
    loadUsersAndGames({ commit }, payload) {
      commit('SET_USERS', payload.users);
      commit('SET_GAMES', payload.games);
    },
    cancelMatch(_, { matchId }) {
      const payload = {
        matchId: matchId,
      };
      connApi.emitCancelMatch(payload);
    },
    onGamesPage(_, { matchId, connCallback, failCallback }) {
      connApi.onGamesPage(matchId, connCallback, failCallback);
    },
    offGamesPage({ commit }, { matchId, pageHidden }) {
      if (!pageHidden) {
        commit('SET_ACTIVE_MATCHID', null);
        commit('SET_ACTIVE_GAMEDATA', null);
      }
      const payload = {
        matchId: matchId
      };
      connApi.offGamesPage(payload);
    },
    async hideMatch({ commit }, { matchId, isDisplayed }) {
      await dbApi.updateMatchDisplayed(matchId, isDisplayed);
      commit('REMOVE_DISPLAYED_MATCH', matchId);
      const matches = await dbApi.getDisplayedMatches();
      commit('SET_DISPLAYED_MATCHES', matches);
    },
    setMatchId({ commit }, { matchId }) {
      commit('SET_ACTIVE_MATCHID', matchId);
    },
    setGameData({ commit }, gameData) {
      commit('SET_ACTIVE_GAMEDATA', gameData);
    },
    toastSuccess(_, { message }) {
      connApi.toastSuccess(message);
    },
    toastError(_, { message }) {
      connApi.toastError(message);
    },
    toastBasic(_, { message }) {
      connApi.toastBasic(message);
    },
    emitGameAction(_, { actionType, matchId, action }) {
      if (actionType === 'try') {
        connApi.emitTryAction(matchId, action);
      } else if (actionType === 'undo') {
        connApi.emitUndoAction(matchId, action);
      } else if (actionType === 'redo') {
        connApi.emitRedoAction(matchId, action);
      } else if (actionType === 'end') {
        connApi.emitEndTurn(matchId);
      } else {
        console.error(`emitAction error: what type of action is this? ${actionType}`);
      }
    }
  },
  getters: {
    getPlaceHolderInfo: (state) => {
      const u = (state.username !== null) ? state.username : "Username";
      const p = (state.password !== null) ? state.password : "Password";
      return {name: u, pwd: p};
    },
  },
});

export default store;