import { createRouter, createWebHistory } from 'vue-router';
import { getToken } from "@/database";

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/components/LoginForm.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/components/RegistrationForm.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/matches',
    name: 'Matches',
    component: () => import('@/components/MatchesPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/creatematch',
    name: 'CreateMatch',
    component: () => import('@/components/CreateMatchPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/game',
    name: 'GamesPage',
    component: () => import('@/components/GamesPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/serveroffline',
    name: 'OfflinePage',
    component: () => import('@/components/OfflinePage.vue'),
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = getToken();
  if (to.meta.requiresAuth && (isAuthenticated === null)) {
    next({ name: 'Login' });
  } else if (!to.meta.requiresAuth && (isAuthenticated !== null) && to.name === 'Login') {
    next({ name: 'Matches' });
  } else {
    next();
  }
});

export default router;
