import { io } from "socket.io-client";
import { useToast } from 'vue-toastification';
import { getAuthObj, updateMatch, getGame, updateGame } from "@/database";
import router from "./router/index";
import store from "./store/index";

let socket = null;
let connected = false;
const toast = useToast();

const toastBasic = (msg) => {
    toast(msg);
}

const toastError = (msg) => {
    toast.error(msg);
}

const toastSuccess = (msg) => {
    toast.success(msg);
}

const connectSocket = () => {
    const authObj = getAuthObj();

    socket = io('https://app.arcadea.us', {
        path: '/client/socket.io',
        auth: authObj,
    });

    socket.on('fetchedMatchData', async (matchesData) => {
        console.log('fetched match data', matchesData);
        for ( let i = 0; i < matchesData.length; i++) {
            await updateMatch(matchesData[i]);
        }
        store.dispatch('loadDisplayedMatches');
    });

    return new Promise((resolve, reject) => {

        socket.on('connect', () => {
            console.log('Connected to the Socket.IO server');
            connected = true;            
            resolve();
        });

        socket.on('disconnect', (reason) => {
            console.log('Disconnected: ', reason);
            
            //Handle "transport close" if it becomes an issue on mobile devices
            if ((reason !== "io client disconnect") && (router.currentRoute.value.name !== 'OfflinePage')) {
                socket.disconnect();
                connected = false;
                router.replace({ name: 'OfflinePage' });
            }
        });

        socket.on("connect_error", (error) => {
            console.log('Connection Error: ', error);
            if (router.currentRoute.value.name !== 'OfflinePage') {
                socket.disconnect();
                connected = false;
                router.replace({ name: 'OfflinePage' });
            }
            reject();
        });
    
        socket.on('error', (msg) => {
            toastError(msg.message);
        });
    });
}

const disconnectFromServer = () => {
    if (socket) {
        socket.disconnect();
        connected = false;
        console.log('Disconnected from the Socket.IO server');
    }
}

const startListeningToEvent = (eventName) => {
    if (socket && connected) {
        socket.on(eventName);
        console.log(`Started listening to the ${eventName} event`);
    }
}

const stopListeningToEvent = (eventName) => {
    if (socket && connected) {
        socket.removeAllListeners(eventName);
        console.log(`Stopped listening to the ${eventName} event`);
    }
}

const onMatchesPage = () => {
    if (socket && connected) {
        console.log("fetching match data");
        socket.emit('fetchMatchData');
    }
}

const acceptInvite = (id, acceptance) => {
    if (socket && connected) {
        socket.emit('acceptInvite', {matchId: id, accepted: acceptance});
    }
}

const getCreateMatchData = () => {
    if (socket && connected) {
        socket.on('fetchedCreateMatchData', (payload) => {
            store.dispatch('loadUsersAndGames', payload);
        });
        socket.emit('fetchCreateMatchData');
    }
}

const emitCreateMatch = (payload) => {
    if (socket && connected) {
        socket.emit('createMatch', payload);
    }
}

const emitCancelMatch = (payload) => {
    if (socket && connected) {
        socket.emit('cancelMatch', payload);
    }
}

const onGamesPage = (mId, connCallback, failCallback) => {
    if (socket && connected) {
        socket.on('joinedMatchRoom', (payload) => {
            const msg = {
                message: payload.message
            };
            connCallback(msg);
        });

        socket.on('leftMatchRoom', (payload) => {
            const msg = {
                message: payload.message
            };
            connCallback(msg);
        });

        socket.on('actionFailed', (payload) => {
            const msg = {
                message: payload.message
            };
            failCallback(msg);
        });

        socket.on('fetchedGameState', async (gameData) => {
            const result = await updateGame(gameData);
            if (result === gameData.matchId) {
                socket.emit('fetchFullGameState', { matchId: gameData.matchId });
            } else {
                const newGameState = await getGame(gameData.matchId);
                store.dispatch('setGameData', newGameState);
            }
        });

        const payload = {
            matchId: mId
        };
        socket.emit('joinMatchRoom', payload);
        socket.emit('fetchGameState', payload);
    }
}

const offGamesPage = (payload) => {
    if (socket && connected) {
        socket.emit('leaveMatchRoom', payload);
        socket.removeAllListeners('joinedMatchRoom');
        socket.removeAllListeners('leftMatchRoom');
        socket.removeAllListeners('actionFailed');
        socket.removeAllListeners('fetchedGameState');
    }
}

const emitUndoAction = (matchId, action) => {
    if (socket && connected) {
        const payload = {
            matchId: matchId,
            action: action
        };
        socket.emit('undoAction', payload);
    }
}

const emitRedoAction = (matchId, action) => {
    if (socket && connected) {
        const payload = {
            matchId: matchId,
            action: action
        };
        socket.emit('redoAction', payload);
    }
}

const emitTryAction = (matchId, action) => {
    if (socket && connected) {
        const payload = {
            matchId: matchId,
            action: action
        };
        socket.emit('tryAction', payload);
    }
}

const emitEndTurn = (matchId) => {
    if (socket && connected) {
        const payload = {
            matchId: matchId
        };
        socket.emit('endTurn', payload);
    }
}

export {
    socket,
    connected,
    toastBasic,
    toastSuccess,
    toastError,
    connectSocket,
    disconnectFromServer,
    startListeningToEvent,
    stopListeningToEvent,
    onMatchesPage,
    acceptInvite,
    getCreateMatchData,
    emitCreateMatch,
    emitCancelMatch,
    onGamesPage,
    offGamesPage,
    emitUndoAction,
    emitRedoAction,
    emitTryAction,
    emitEndTurn
};