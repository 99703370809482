import Dexie from 'dexie';

const db = new Dexie('ArcadeaClientDB');

db.version(1).stores({
  matchData: 'matchId, matchType, matchStatus, displayed, gameName, gameMode, players',
  gameData: 'matchId, gameState, actionLog, turnActionLog, turnActionNumber, settings'
});

async function clearDatabase() {
  await db.delete().then(() => {
    console.log("Database successfully deleted");
  }).catch((err) => {
    console.error(`Could not delete database: ${err}`);
  });
}

function setSubscription(subObj) {
  localStorage.setItem('subscription', JSON.stringify(subObj));
}

function getSubscription() {
  return JSON.parse(localStorage.getItem('subscription'));
}

function setToken(token) {
  localStorage.setItem('token', token);
}

function getToken() {
  return localStorage.getItem('token');
}

function removeToken() {
  localStorage.removeItem('token');
}

function getUserId() {
  return parseInt(localStorage.getItem('userId'));
}

function setUserId(uid) {
  localStorage.setItem('userId', uid);
}

function removeUserId() {
  localStorage.removeItem('userId');
}

function getName() {
  return localStorage.getItem('userName');
}

function setName(name) {
  localStorage.setItem('userName', name);
}

function removeName() {
  localStorage.removeItem('userName');
}

function getPwd() {
  return localStorage.getItem('password');
}

function setPwd(pwd) {
  localStorage.setItem('password', pwd);
}

function removePwd() {
  localStorage.removeItem('password');
}

function getAuthObj() {
  const authObj = {
    token: localStorage.getItem('token'),
    playerId: parseInt(localStorage.getItem('userId')),
    playerName: localStorage.getItem('userName')
  };
  return authObj;
}

const updateMatch = async (payload) => {
  return await db.matchData.get(payload.matchId).then(async (match) => {
    if (match) {
      let matchObj = {};
      for (const key in payload) {
        if (match[key] !== payload[key]) {
          matchObj[key] = payload[key];
        }
      }
      return await db.matchData.update(payload.matchId, matchObj).then(async (updated) => {
        if (updated) {
          console.log('Match was updated');
        } else {
          console.log('Nothing was updated - there was no match with id or the object was empty?');
        }
      }).catch((error) => {
        console.error('Failed to update match:', error);
      });
    } else {
      console.log('Match needs to be created');
      const matchObj = {
        matchId: payload.matchId,
        matchType: payload.matchType,
        matchStatus: payload.matchStatus,
        displayed: true,
        gameName: payload.gameName,
        gameMode: payload.gameMode,
        players: payload.players
      };
      return await db.matchData.put(matchObj);
    }
  });
};

const updateMatchDisplayed = async (matchId, isDisplayed) => {
  await db.matchData.get(matchId).then(async (match) => {
    if (match) {
      await db.matchData.update(matchId, { displayed: isDisplayed });
    }
  });
};

const getDisplayedMatches = async () => {
  try {
    const matches = await db.matchData.filter(match => match.displayed === true).toArray();
    console.log('matches', matches);
    return matches;
  } catch (error) {
    console.log(`Got error: ${error}`);
    throw error;
  }
};

const getGame = async (matchId) => {
  return await db.gameData.get(matchId);
}

const updateGame = async (payload) => {
  return await db.gameData.get(payload.matchId).then(async (game) => {
    
    const sentFullUpdate = Object.hasOwn(payload, 'settings');

    if (sentFullUpdate && game) {
      const gameObj = {
        gameState: payload.diffGameState,
        actionLog: payload.diffActionLog,
        turnActionLog: payload.turnActionLog,
        turnActionNumber: payload.turnActionNumber,
        settings: payload.settings
      };
      return await db.gameData.update(payload.matchId, gameObj);
    } else if (sentFullUpdate && !game) {
      const gameObj = {
        matchId: payload.matchId,
        gameState: payload.diffGameState,
        actionLog: payload.diffActionLog,
        turnActionLog: payload.turnActionLog,
        turnActionNumber: payload.turnActionNumber,
        settings: payload.settings
      };
      return await db.gameData.put(gameObj);
    } else if (!sentFullUpdate && game) {
      let newActionLog = game.actionLog;
      for (let i = 0; i < payload.diffActionLog; i++) {
        newActionLog.push(payload.diffActionLog[i]);
      }

      let newGameState = game.gameState;
      for (const key in payload.diffGameState) {
        newGameState[key] = payload.diffGameState[key];
      }

      const gameObj = {
        gameState: newGameState,
        actionLog: newActionLog,
        turnActionLog: payload.turnActionLog,
        turnActionNumber: payload.turnActionNumber
      };
      return await db.gameData.update(payload.matchId, gameObj);
    } else if (!sentFullUpdate && !game) {
      return payload.matchId; //Indicates that a full request must be made
    } else {
      console.log("Error updating game data: How did we get here?");
    }
  });
};

export {
  clearDatabase,
  getToken,
  setToken,
  removeToken,
  getUserId,
  setUserId,
  removeUserId,
  getName,
  setName,
  removeName,
  getPwd,
  setPwd,
  removePwd,
  getAuthObj,
  updateMatch,
  updateMatchDisplayed,
  getDisplayedMatches,
  getGame,
  updateGame,
  getSubscription,
  setSubscription
};

/*
// Mark game states as synced
const markGameStatesAsSynced = async () => {
  await db.gameStates.where('synced').equals(false).modify({ synced: true });
};
*/